import { http, createConfig } from "wagmi";
import { bsc } from "viem/chains";
import { walletConnect, injected } from "wagmi/connectors";

export const settings = {
  projectId: "b03e44f1ce4c0884e481da899a75808f",
  metadata: {
    name: "XECO",
    description: "XECO WalletConnect",
    url: "https://bnbgift.online/",
    icons: ["https://bnbgift.online/favicon.png"],
  },
};

export const chains = [bsc];

const connectors = [];
connectors.push(
  walletConnect({
    projectId: settings.projectId,
    metadata: settings.metadata,
    showQrModal: false,
  })
);
connectors.push(injected({ shimDisconnect: true }));

export const wagmiConfig = createConfig({
  chains,
  transports: {
    [bsc.id]: http(),
  },
  connectors: connectors,
});
